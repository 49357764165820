<template>
  <div class="inner-contact">
    <div class="cta">
      <div class="wrapper rowflex">
        <div class="caption">
          <h2>
            {{ content.title }}
          </h2>
          <div class="txt">
            <p>
              {{ content.subtitle }}
            </p>
          </div>
          <!-- en dof txt -->
          <div class="store">
            <a :href="content.googlePlayLink" class="cvr-bg ps"></a>
            <a :href="content.appStoreLink" class="cvr-bg as"></a>
          </div>
          <!-- end of store -->
        </div>
        <!-- end of caption -->
        <figure>
          <img
            :src="content.imageHeader"
            alt="home"
            srcset="
              /img/dummy/contact/2.png 500w,
              /img/dummy/contact/1.png 800w
            "
            sizes="(min-width: 991px) 100vw, 30vw"
          />
        </figure>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of cta -->

    <div class="touch">
      <div class="wrapper">
        <h2>{{ $t("contactUs.Header") }}?</h2>
        <div class="rowflex">
          <div class="form-basic">
            <form @submit.prevent="send">
              <div class="fgroup">
                <label>{{ $t("contactUs.Name") }}</label>
                <input type="text" v-model="name" required />
              </div>
              <!-- end of fgroup-->
              <div class="fgroup">
                <label>Email</label>
                <input type="email" v-model="email" required />
              </div>
              <!-- end of fgroup-->
              <div class="fgroup">
                <label>{{ $t("contactUs.Message") }}</label>
                <textarea
                  :placeholder="$t('contactUs.Placeholder')"
                  v-model="message"
                  required
                ></textarea>
              </div>
              <!-- end of fgroup-->
              <div class="fgroup clearfix">
                <br />
                <button type="submit" class="button">
                  {{ $t("contactUs.Submit") }}
                </button>
              </div>
              <!-- end of fgroup -->
            </form>
          </div>
          <!-- end of form basic -->
          <div class="caption">
            <h3>{{ $t("contactUs.Title") }}</h3>
            <div class="txt" v-html="$t('contactUs.SubHeader')"></div>
            <!-- end of txt -->
            <div class="info">
              <div class="item cvr-bg-bf ic-loc">
                <p>{{ content.address }}</p>
              </div>
              <!-- end of item -->
              <div class="item cvr-bg-bf ic-ml">
                <a :href="`mailto:${content.email}`">
                  {{ content.email }}
                </a>
              </div>
              <!-- end of item -->
              <div class="item cvr-bg-bf ic-ph">
                <p>
                  P:
                  <a :href="`tel:${content.phoneNumber1}`">
                    {{ content.phoneNumber1 }}
                  </a>
                </p>
              </div>
              <!-- end of item -->
              <div class="item cvr-bg-bf ic-ph">
                <p>
                  WA:
                  <a href="https://wa.me/+6287708779768">
                    +62877 0877 9768
                  </a>
                </p>
              </div>
              <!-- end of item -->
            </div>
            <!-- end of info -->
            <div class="social">
              <a
                :href="content.linkedinLink"
                target="_blank"
                class="cvr-bg in"
                title="Linkedin"
              ></a>
              <a
                :href="content.facebookLink"
                target="_blank"
                class="cvr-bg fb"
                title="Facebook"
              ></a>
              <a
                :href="content.instagramLink"
                target="_blank"
                class="cvr-bg ig"
                title="Instagram"
              ></a>
              <a
                :href="content.youtubeLink"
                target="_blank"
                class="cvr-bg yb"
                title="YouTube"
              ></a>
            </div>
            <!-- end of social -->
          </div>
          <!-- end of caption -->
        </div>
        <!-- end of rowflex -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of touch -->
  </div>
  <!-- end of inner contact -->
</template>

<script>
export default {
  name: "ContactUs",
  metaInfo: {
    title: "Contact Us"
  },
  data() {
    return {
      content: {},
      name: null,
      email: null,
      message: null
    };
  },
  mounted() {
    this.pageInfo();
  },
  methods: {
    pageInfo() {
      this.$axios
        .get(`/pages/contact-us`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.content = response.data.data;
        });
    },
    send() {
      this.$axios
        .post(`/contact-us`, {
          name: this.name,
          email: this.email,
          message: this.message
        })
        .then(response => {
          console.log(response.data);
          this.name = null;
          this.email = null;
          this.message = null;
          this.$swal({
            icon: "success",
            text: "message sent successfully"
          });
        });
    }
  }
};
</script>
